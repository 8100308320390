<script>
/***
 Name: index
 Code: sa0ChunLuyu
 Time: 2021/11/10 11:49
 Remark: 检测方法
 */
import LeftNav from './left_nav/left_nav.vue'
import EditTable from './edit_table/edit_table.vue'

export default {
  components: {
    LeftNav, EditTable
  },
  data() {
    return {
      nav_show: true
    }
  },
  mounted() {
  },
  methods: {
    toReload() {
      this.nav_show = false
      setTimeout(() => {
        this.nav_show = true
      })
    }
  }
}
</script>
<template>
  <div>
    <a-space>
      <LeftNav v-if="nav_show"></LeftNav>
      <EditTable :reload="toReload"></EditTable>
    </a-space>
  </div>
</template>
<style scoped>

</style>
