<script>
    /***
     Name: example3
     Code: sa0ChunLuyu
     Time: 2021/10/16 09:47
     Remark: 编辑抽屉示例的抽屉组件
     */
    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close', 'getStype'],
        data() {
            return {
                check_active:true,
                form: {},
                labelCol: {span: 4},
                wrapperCol: {span: 14},
                file:{
                    name:'',
                }
            }
        },
        mounted() {
        },
        methods: {
            authoclick(item) {
                this.$router.push('../../../jiancebiaozhunwendang/index/');
            },
            //文件上传
            fileUpload(e) {
                let file = e.target.files[0]
                console.log(file)
                this.file = file
                this.form.file_name = file.name
                this.fileUploadDo()
            },

            fileUploadDo() {
                let param = new FormData()
                param.append('file', this.file)
                this.$sa0.file({
                    url: this.$api('upload_file'),
                    data: param,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            layer.msg('上传成功')
                            this.form.file_url = res.data.url
                        },
                        error: (res) => {
                            layer.msg('上传失败')
                        }
                    })
                })
            },

            doClick() {
                let data = {
                    id: this.form.id,
                    serial_code: this.form.serial_code,
                    name:this.form.name,
                    xize_code: this.form.xize_code,
                    file_url: this.form.file_url,
                    file_name:this.form.file_name,
                }
                console.log(data)
                this.$sa0.post({
                    url: this.$api('Create_document'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            if(this.check_active){
                                this.getStype(Number(response.data.id))
                            }
                            this.close()
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },
            onChange1(e) {
                console.log(`checked = ${e.target.checked}`);
            },
            onChange(targetKeys) {
                console.log('Target Keys:', targetKeys);
                this.targetKeys = targetKeys;
            },
            onChecked(_, e, checkedKeys, itemSelect) {
                const {eventKey} = e.node;
                itemSelect(eventKey, !isChecked(checkedKeys, eventKey));
            },
            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },
        }
    }
</script>
<template>
    <div>
        <a-drawer title="新建标准文档" width="640" placement="right" :closable="true" :visible="visible" @close="onClose">
            <div>
                <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
                    <a-form-model-item label="编号">
                        <a-input v-model="form.serial_code" class="w300"/>
                    </a-form-model-item>
                    <a-form-model-item label="名称">
                        <a-input v-model="form.name" class="w300"/>
                    </a-form-model-item>
                    <a-form-model-item label="细则编号">
                        <a-input v-model="form.xize_code" class="w300"/>
                    </a-form-model-item>
                    <a-form-model-item label="文件">
                        <div class="btn">
                            <div class="btn-up"><i class="el-icon-upload"></i> 上传文件
                                <input @change="fileUpload" type="file">
                            </div>
                            <div class="btn-new">
                                <span v-if="file">{{ file.name }}</span>
                            </div>
                        </div>
                    </a-form-model-item>
                    <a-form-model-item label="文件名称">
                        <a-input v-model="form.file_name" class="w300"/>
                    </a-form-model-item>
                    <a-form-item :wrapper-col="{ span: 12, offset: 4 }">
                        <a-checkbox v-model="check_active" @change="onChange1">创建之后选中</a-checkbox>
                    </a-form-item>
                    <a-form-item :wrapper-col="{ span: 12, offset: 4 }">
                        <a-button type="primary" class="mt25" @click="doClick()" >创建</a-button>
                    </a-form-item>
                </a-form-model>
                <p style="color: #1890ff; margin-left: 100px" @click="authoclick">前往权限管理页面></p>
            </div>
        </a-drawer>
    </div>
</template>
<style scoped>
    #components-form-demo-validate-other .dropbox {
        height: 180px;
        line-height: 1.5;
        }

    .btn {
        position: relative;
        }

    .btn-up {
        display: inline-block;
        position: relative;
        margin-right: 10px;
        padding: 0 15px;
        background: #09aaff;
        color: #fff;
        border: solid 1px #09aaff;
        border-radius: 3px
        }

    .btn-up input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0
        }

    .btn-new {
        display: inline-block;
        position: relative;
        padding: 5px 15px;
        /*border:solid 1px #09aaff;*/
        color: #09aaff;
        background: #fff;
        border-radius: 3px
        }

    .btn-up, .btn-up input, .btn-new {
        cursor: pointer;
        }


</style>
