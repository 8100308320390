<script>

    /***
     Name: edit_dir
     Code: sa0ChunLuyu
     Time: 2021/11/10 16:53
     Remark: 编辑文件夹
     */
    import Creatfile from './creatfile.vue';
    export default {
        props: ['create_do', 'reload'],
        components: {
            Creatfile
        },
        data() {
            return {
                drawer_visible: false,
                doc_list: [],
                method_info: {
                    id: 0,
                    pid: 0,
                    name: ''
                }
            }
        },
        watch: {
            $route: {
                handler() {
                    this.getItemInfo()
                },
                deep: true
            },
            create_do() {
                this.getItemInfo()
            }
        },
        mounted() {
            // this.getDocList()
            this.getItemInfo()
            this.getStype(0)
        },
        methods: {
            getStype(id) {
                this.$sa0.post({
                    url: this.$api('获取文档所有列表'),
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.doc_list = response.data.list
                            if (id === 0) {
                                this.method_info.document_id = Number(this.doc_list[0].id)
                            } else {
                                this.method_info.document_id = id
                            }
                            this.onCloseDrawer()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 定义 关闭抽屉时的 函数
            onCloseDrawer() {
                this.drawer_visible = false;
            },
            // 定义 打开抽屉时的 函数
            showDrawer() {
                this.drawer_visible = true;
            },

            thisReload() {
                this.getItemInfo()
                this.reload()
            },
            getItemInfo() {
                if (this.create_do === 1) {
                    this.$sa0.post({
                        url: this.$api('根据Id获取检测方法详情'),
                        data: {
                            id: this.$route.params.id
                        }
                    }).then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (response) => {
                                this.method_info = {
                                    id: 0,
                                    pid: response.data.det_method.id,
                                    name: '',
                                    remark: '',
                                    document_id: '',
                                }
                            },
                            error: (response) => {
                                layer.msg(response.message)
                            },
                        })
                    })
                } else {
                    if (Number(this.$route.params.id) !== 0) {
                        this.$sa0.post({
                            url: this.$api('根据Id获取检测方法详情'),
                            data: {
                                id: this.$route.params.id
                            }
                        }).then((response) => {
                            this.$sa0.response({
                                response: response.data,
                                then: (response) => {
                                    this.method_info = response.data.det_method
                                },
                                error: (response) => {
                                    layer.msg(response.message)
                                },
                            })
                        })
                    } else {
                        this.method_info = {
                            id: 0,
                            pid: 0,
                            name: '',
                            document_id: '',
                            remark: '',
                        }
                    }
                }
            },
            updateDo() {
                this.$sa0.post({
                    url: this.$api('修改检测方法'),
                    data: {
                        "name": this.method_info.name,
                        "pid": this.method_info.pid,
                        "type": "1",
                        "document_id": this.method_info.document_id,
                        "remark": this.method_info.remark,
                        "id": this.method_info.id,
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg('修改成功')
                            this.thisReload()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            createDo() {
                this.$sa0.post({
                    url: this.$api('创建检测方法'),
                    data: {
                        "name": this.method_info.name,
                        "pid": this.method_info.pid,
                        "type": "1",
                        "document_id": this.method_info.document_id,
                        "remark": this.method_info.remark,
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg('新建成功')
                            this.$router.push({
                                params: {
                                    id: response.data.id
                                }
                            })
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            saveClick() {
                if (this.create_do) {
                    this.createDo();
                } else {
                    this.updateDo();
                }
            },
        }
    }
</script>
<template>
    <div>
        <div class="edit_dir_wrapper">
            <div class="input_table_title_wrapper"><b>{{ create_do === 0 ? '编辑' : '新建' }}检测方法</b></div>
            <div class="mt-6">
                <div class="mt-3">
                    <a-space>
                        <div class="input_title_wrapper"><b>检测方法名称：</b></div>
                        <div>
                            <a-input v-model="method_info.name" class="input_wrapper"></a-input>
                        </div>
                    </a-space>
                </div>
                <div class="mt-3">
                    <a-space>
                        <div class="input_title_wrapper"><b>标准文档:</b></div>
                        <div>
                            <a-select class="select_wrapper" v-model="method_info.document_id">
                                <a-select-option v-for="(i,k) in doc_list" :value="i.id">{{ i.name }}</a-select-option>
                            </a-select>
                        </div>
                        <div>
                            <a-button type="primary"  @click="showDrawer">新建检测文档</a-button>
                        </div>
                    </a-space>
                </div>
                <div class="mt-3">
                    <a-space>
                        <div class="input_title_wrapper"><b>备注：</b></div>
                        <div>
                            <a-input v-model="method_info.remark" class="input_wrapper"></a-input>
                        </div>
                    </a-space>
                </div>
                <div class="mt-3">
                    <a-space>
                        <div class="input_title_wrapper"></div>
                        <div>
                            <button @click="saveClick()" class="layui-btn layui-btn-normal">{{
                                create_do === 0 ? '保存' : '新建'
                                }}
                            </button>
                        </div>
                    </a-space>
                </div>
            </div>
        </div>
        <Creatfile :getStype="getStype" :close="onCloseDrawer" :visible="drawer_visible"></Creatfile>
    </div>
</template>
<style scoped>
    .select_wrapper{
        width: 300px;
        }

    .input_title_wrapper{
        width: 120px;
        text-align: right;
        }

    .input_wrapper{
        width: 300px;
        }

    .input_table_title_wrapper{
        font-size: 20px;
        }
</style>
